import React from 'react';
import './App.css';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer'; // Fußball-Icon
import { Typography } from '@mui/material';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <SportsSoccerIcon 
          style={{ 
            fontSize: 100, 
            marginBottom: 20, 
            animation: 'App-logo-spin infinite 20s linear' // CSS-Animation anwenden
          }} 
        /> {/* Fußball-Icon mit CSS-Animation */}
        <Typography variant="h4" component="h1">
          Der ultimative Fussball-Shop startet bald!
        </Typography>
        <Typography variant="h6" component="p" style={{ marginTop: 10 }}>
          Dilocco.ch – Ihr Ziel für die besten Fussballprodukte.
        </Typography>
        <Typography variant="body1" component="p" style={{ marginTop: 20 }}>
          Bleiben Sie gespannt und bereiten Sie sich vor – schon bald finden Sie hier alles, was das Fussballherz begehrt!
        </Typography>
      </header>
    </div>
  );
}

export default App;
